import ResponsiveAppBar from "./Components/Nav/Nav.tsx";
import About from "./Components/About";
import Blog from "./Components/Blog/Blog";
import Contact from "./Components/Contact/Contact";
import Home from "./Components/Home/Home"
import APIs from "./Components/APIs/APIs"
import {Route, Routes} from "react-router"

function App() {
  const pages = ['About', 'Blog', 'Contact', 'APIs'];
  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  return (
    <div className="App"> 
    <ResponsiveAppBar title = 'JODO' pages={pages} settings={settings}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="About" element={<About />} />
        <Route path="Blog" element={<Blog />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="APIs" element={<APIs />} />
      </Routes>

    </div>
  );
}

export default App;
