import React from "react";
import '../../Styles/Page.css'

const APIs = async () => {
    let request = new XMLHttpRequest();
    request.open("GET", 'https://localhost:7099/api/Fortune/Rank?rank=3')
    request.send();
    
    return <div className="APIs" id="page">  
      {request}
    </div>;
  }
  
export default APIs;